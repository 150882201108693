import defaults from "./default.config"
import deepMergeObjects from "../assets/helpers/mergeObjects.js"

export default defineI18nConfig(() => (deepMergeObjects(defaults, {
	messages: {
		en: {
			//TODO: Maintain Alphabetical order!!!
			cookiesNotice: "At Shambhala headquarters, we use analytical tools to help make your experience better. We’d love to have you" +
				" accept our cookies but we’ll always respect your data choices. Some cookies are necessary for our page to work.",
			discoverMore: "Discover More",
			errorPageMissingBody: "Looks like you wandered into the rabbit hole.\nSorry, there is nothing here at this time.",
			homepageBody_addOns: 'Take a look at some of our Add-Ons. If none of them tickle your fancy, click "Next" to skip.\n\n',
			homepageBody_checkout: "We can't wait to see you on the dancefloor!\n\n",
			homepageBody_confirmation: "A confirmation email has been sent to {email}.\nWe will send you an additional email once your order has been processed.",
			homepageBody_donations: 'Shambhala Music Festival is proud to announce our continued support for the ANKORS non-profit society. The Nelson based organization has a mandate to "work from a wellness model; through support and advocacy; harm reduction, prevention and education; delivery of programs and services that are client-centred and focused on the whole person; and partnerships with others."',
			homepageBody_lodging: 'Select your desired lodging experience. If you already have a place to \nstay, use the icons above to navigate or click "Next" to skip.',
			homepageBody_merch: 'Explore our limited edition 26th Anniversary Merchandise and prepare to come HOME for 26 years of Shambhala\n\n',
			homepageBody_tickets: 'Shambhala Music Festival July 25th-28th, 2025 in Salmo, BC.\nUse the icons above to navigate or click "Next" to skip.',
			homepageBody_volunteers: "You must purchase a regular ticket for us to collect your volunteer deposit. \nThe deposit will be returned to you after you have completed the minimum volunteer hours.",
			homepageHeading_addOns: "Interested in some upgrades?",
			homepageHeading_checkout: "All set? Let's get you checked out.",
			homepageHeading_confirmation: "Thanks for your order {name}!",
			homepageHeading_donations: "ANKORS",
			homepageHeading_lodging: "Choose your Shambhalodging!",
			homepageHeading_merch: "Merchandise",
			homepageHeading_tickets: "Start your journey Home",
			homepageHeading_volunteers: "Volunteer Deposits",
			maintenance1: "Our site is down for maintenance, we're working to resolve this issue as quickly as possible",
			maintenance2: "Sending out Shambhalove to all our Farmily,\nwe can't wait to welcome you back Home.",
		},
		fr: {},
	}
})))
